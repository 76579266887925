import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import HomeSectionIntro from "../components/home-section-intro";
import HomeSectionLastProject from "../components/home-section-last-project";
import HomeSectionSkills from "../components/home-section-skills";
import HomeSectionAbout from "../components/home-section-about";
import SEO from "../components/seo";
import { Query } from "../graphql-types";

interface Props {
  data: Query;
}

export default ({ data }: Props) => {
  if (data && data.allContentfulHomePage && data.allContentfulHomePage.edges) {
    const { edges } = data.allContentfulHomePage;
    const page = edges.map(e => e.node)[0];

    return (
      <Layout>
        <SEO data={page.seo} />
        <HomeSectionIntro page={page} />
        <HomeSectionLastProject page={page} />
        <HomeSectionSkills />
        <HomeSectionAbout page={page} />
      </Layout>
    );
  }

  return null;
};

export const query = graphql`
  {
    allContentfulHomePage {
      edges {
        node {
          title
          seo {
            ...SEO
          }
          aboutBlock1
          aboutBlock2 {
            fluid(maxWidth: 500, quality: 80) {
              ...GatsbyContentfulFluid
            }
          }
          aboutBlock3 {
            fluid(maxWidth: 500, quality: 80) {
              ...GatsbyContentfulFluid
            }
          }
          lastProject {
            title
            slug
            fullPreviewImage {
              fluid(maxWidth: 1400, quality: 80) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  }
`;
