import * as React from "react";
import styled from "styled-components";

import { Container } from "./style";

const Svg = styled.svg`
  height: 20px;
  width: auto;
`;

const Facebook = () => (
  <Svg
    version="1.1"
    x="0px"
    y="0px"
    width="470.513px"
    height="470.513px"
    viewBox="0 0 470.513 470.513"
    fill="currentColor"
  >
    <g>
      <path
        d="M271.521,154.17v-40.541c0-6.086,0.28-10.8,0.849-14.13c0.567-3.335,1.857-6.615,3.859-9.853
		c1.999-3.236,5.236-5.47,9.706-6.708c4.476-1.24,10.424-1.858,17.85-1.858h40.539V0h-64.809c-37.5,0-64.433,8.897-80.803,26.691
		c-16.368,17.798-24.551,44.014-24.551,78.658v48.82h-48.542v81.086h48.539v235.256h97.362V235.256h64.805l8.566-81.086H271.521z"
      />
    </g>
  </Svg>
);

const Instagram = () => (
  <Svg version="1.1" x="0px" y="0px" viewBox="0 0 512 512" fill="currentColor">
    <g>
      <g>
        <path
          d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160
			C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48
			h192c61.76,0,112,50.24,112,112V352z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128S326.688,128,256,128z M256,336
			c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80C336,300.096,300.096,336,256,336z"
        />
      </g>
    </g>
    <g>
      <g>
        <circle cx="393.6" cy="118.4" r="17.056" />
      </g>
    </g>
  </Svg>
);

const Twitter = () => (
  <Svg
    version="1.1"
    x="0px"
    y="0px"
    width="449.956px"
    height="449.956px"
    viewBox="0 0 449.956 449.956"
    fill="currentColor"
  >
    <g>
      <path
        d="M449.956,85.657c-17.702,7.614-35.408,12.369-53.102,14.279c19.985-11.991,33.503-28.931,40.546-50.819
		c-18.281,10.847-37.787,18.268-58.532,22.267c-18.274-19.414-40.73-29.125-67.383-29.125c-25.502,0-47.246,8.992-65.24,26.98
		c-17.984,17.987-26.977,39.731-26.977,65.235c0,6.851,0.76,13.896,2.284,21.128c-37.688-1.903-73.042-11.372-106.068-28.407
		C82.46,110.158,54.433,87.46,31.403,59.101c-8.375,14.272-12.564,29.787-12.564,46.536c0,15.798,3.711,30.456,11.138,43.97
		c7.422,13.512,17.417,24.455,29.98,32.831c-14.849-0.572-28.743-4.475-41.684-11.708v1.142c0,22.271,6.995,41.824,20.983,58.674
		c13.99,16.848,31.645,27.453,52.961,31.833c-7.995,2.091-16.086,3.138-24.269,3.138c-5.33,0-11.136-0.475-17.416-1.42
		c5.9,18.459,16.75,33.633,32.546,45.535c15.799,11.896,33.691,18.028,53.677,18.418c-33.498,26.262-71.66,39.393-114.486,39.393
		c-8.186,0-15.607-0.373-22.27-1.139c42.827,27.596,90.03,41.394,141.612,41.394c32.738,0,63.478-5.181,92.21-15.557
		c28.746-10.369,53.297-24.267,73.665-41.686c20.362-17.415,37.925-37.448,52.674-60.097c14.75-22.651,25.738-46.298,32.977-70.946
		c7.23-24.653,10.848-49.344,10.848-74.092c0-5.33-0.096-9.325-0.287-11.991C421.785,120.202,437.202,104.306,449.956,85.657z"
      />
    </g>
  </Svg>
);

const Item = styled.li`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

interface Props {
  inline: boolean;
}

export default ({ inline }: Props) => (
  <Container inline={inline}>
    <Item>
      <a
        href="https://www.facebook.com/studio.dankana/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Facebook />
      </a>
    </Item>
    <Item>
      <a
        href="https://www.instagram.com/studio.dankana/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Instagram />
      </a>
    </Item>
  </Container>
);
