import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

interface Props {
  open: boolean;
}

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 700px;
  background-color: #d66569;
  z-index: 100002;
  color: white;
  padding: 50px;
  transform: ${({ open }: Props) => (open ? "none" : "translateX(100%)")};
  transition: 300ms;
  box-sizing: border-box;
`;

const Ul = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const Li = styled.li`
  font-family: Fira Sans;
  margin-top: 20px;
  font-size: 25px;
  font-weight: 700;

  &:first-child {
    font-family: Bosk;
    font-size: 45px;
    font-weight: 400;
  }
`;

export default function Panel({ open }: Props) {
  return (
    <Container open={open}>
      <nav>
        <Ul>
          <Li>
            <Link to="/">Hello !</Link>
          </Li>
          <Li>
            <Link to="/projects">Réalisations</Link>
          </Li>
          <Li>
            <Link to="/prints">Linogravures</Link>
          </Li>
          <Li>
            <Link to="/about">À propos</Link>
          </Li>
          <Li>
            <Link to="/contact">Contact</Link>
          </Li>
        </Ul>
      </nav>
    </Container>
  );
}
