import * as React from "react";

import SocialNetworks from "../social-networks";
import { Container, VerticalTitle, Bottom } from "./style";

export default () => (
  <Container>
    <VerticalTitle>Studio créatif</VerticalTitle>
    <Bottom>
      <SocialNetworks />
    </Bottom>
  </Container>
);
