import styled from "styled-components";

const Title = styled.h1`
  font-family: Bosk;
  font-size: 50px;
  font-weight: 400;
  text-align: center;
  margin: 0 0 50px 0;

  @media screen and (max-width: 768px) {
    margin: 0 0 25px 0;
    font-size: 40px;
  }
`;

export default Title;
