import styled from "styled-components";

import Border from "./border";

export default styled(Border)`
  top: 0;
  left: 0;
  right: 0;
  height: 80px;

  @media screen and (max-width: 768px) {
    height: 60px;
  }
`;
