import * as React from "react";
import styled from "styled-components";

const Item = styled.span`
  display: block;
  height: 3px;
  width: 100%;
  background-color: black;

  transition: 150ms;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const Container = styled.button`
  position: fixed;
  top: 30px;
  right: 80px;
  z-index: 100001;
  display: block;
  width: 30px;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
  outline: 0;

  &:hover {
    ${Item} {
      &:nth-child(2) {
        transform: translateX(-20%);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    right: 40px;
  }

  @media screen and (max-width: 768px) {
    right: 20px;
    top: 22px;

    ${Item} {
      &:nth-child(2) {
        transform: translateX(-20%);
      }
    }
  }
`;

interface Props {
  onClick: () => void;
}

export default function Burger({ onClick }: Props) {
  return (
    <Container onClick={onClick}>
      <Item />
      <Item />
      <Item />
    </Container>
  );
}
