import * as React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { Parallax } from "react-scroll-parallax";

import HomeSection from "../home-section";
import Button from "../button";
import { ContentfulHomePage } from "../../graphql-types";

const Container = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  width: 70%;
  position: relative;
  display: flex;
  height: calc(100vh - 160px);
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: inherit;
  }
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 145px);
  text-align: center;

  @media screen and (max-width: 768px) {
    height: inherit;
  }
`;

const ImgWrapper = styled.div`
  width: 80%;
  margin-bottom: 30px;
`;

const Title = styled.h2`
  font-family: Bosk;
  font-size: 50px;
  font-weight: 400;
  color: white;
  margin: 0;
  padding: 30px;
  @media screen and (max-width: 768px) {
    font-size: 40px;
    margin-bottom: 50px;
  }
`;

const ProjectTitle = styled.h3`
  font-family: Bosk;
  font-size: 40px;
  font-weight: 400;
  color: white;
  margin: 0;
  margin-bottom: 20px;
`;

interface Props {
  page: ContentfulHomePage;
}

export default ({ page }: Props) => (
  <HomeSection color="#92b6b4">
    <Container>
      <Left>
        <div style={{ width: "100%" }}>
          <Parallax offsetXMax={-10} offsetXMin={0} slowerScrollRate>
            <ImgWrapper>
              <Img
                fluid={page.lastProject.fullPreviewImage.fluid}
                alt={page.lastProject.title}
              />
            </ImgWrapper>
          </Parallax>

          <Title>Notre dernière aventure...</Title>
        </div>
      </Left>
      <Right>
        <div>
          <ProjectTitle>{page.lastProject.title}</ProjectTitle>

          <Link to={`/project/${page.lastProject.slug}`}>
            <Button color="#92b6b4">Voir le projet</Button>
          </Link>
        </div>
      </Right>
    </Container>
  </HomeSection>
);
