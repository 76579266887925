import styled from "styled-components";

interface Props {
  inline: boolean;
}

function border({ inline }: Props) {
  return inline ? "transparent" : "black";
}

function display({ inline }: Props) {
  return inline ? "flex" : "block";
}

function marginRight({ inline }: Props) {
  return inline ? "10px" : 0;
}

function justifyContent({ inline }: Props) {
  return inline ? "center" : "flex-start";
}

export default styled.ul`
  margin: 0;
  list-style-type: none;
  border-top: solid 1px ${border};
  border-bottom: solid 1px ${border};
  padding: 10px 0;
  display: ${display};

  li {
    margin-right: ${marginRight};
  }

  @media screen and (max-width: 768px) {
    justify-content: ${justifyContent};
  }
`;
