import * as React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

import HomeSection from "../home-section";
import Button from "../button";
import { Title, ButtonContainer, Blocks, Block } from "./style";
import { ContentfulHomePage } from "../../graphql-types";

interface Props {
  page: ContentfulHomePage;
}

const HomeSectionAbout = ({ page }: Props) => (
  <HomeSection color="#2d0e2f">
    <Title>Késako ?</Title>
    <Blocks>
      <Block>
        <p>{page.aboutBlock1}</p>
      </Block>
      <Block>
        <Img fluid={page.aboutBlock2.fluid} />
      </Block>
      <Block>
        <Img fluid={page.aboutBlock3.fluid} />
      </Block>
    </Blocks>
    <ButtonContainer>
      <Link to="/contact">
        <Button color="#2d0e2f">Rencontrons nous →</Button>
      </Link>
    </ButtonContainer>
  </HomeSection>
);

export default HomeSectionAbout;
