import styled from "styled-components";

export default styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  @media screen and (max-width: 1024px) {
    width: 40px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
