import * as React from "react";

import {
  Main,
  BorderLeft,
  BorderBottom,
  BorderRight,
  BorderTop
} from "./style";

interface Props {
  children: React.ReactNode;
}

export default ({ children }: Props) => (
  <>
    <BorderTop />
    <BorderLeft />
    <BorderBottom />
    <BorderRight />
    <Main>{children}</Main>
  </>
);
