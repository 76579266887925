import * as React from "react";

import Burger from "./burger";
import Panel from "./panel";
import Overlay from "./overlay";

interface RenderProps {
  open: boolean;
  toggle: () => void;
  closingDuration: number;
  closing: boolean;
}

interface Props {
  closingDuration: number;
  children: (render: RenderProps) => React.ReactNode;
}

interface State {
  open: boolean;
  closing: boolean;
}

export default class MenuContainer extends React.PureComponent<Props, State> {
  state = {
    open: false,
    closing: false
  };

  static defaultProps = {
    closingDuration: 300
  };

  close = () => {
    const { closingDuration } = this.props;

    this.setState({ closing: true }, () => {
      setTimeout(() => {
        this.setState({ open: false, closing: false });
      }, closingDuration);
    });
  };

  open = () => {
    this.setState({ open: true });
  };

  render() {
    const { children, closingDuration } = this.props;

    return children({
      open: this.state.open,
      toggle: this.state.open ? this.close : this.open,
      closingDuration,
      closing: this.state.closing
    });
  }
}

export { Burger, Panel, Overlay };
