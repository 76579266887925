import styled from "styled-components";

interface Props {
  color?: string;
}

function mainColor({ color }: Props) {
  return color ? "white" : "black";
}

const Button = styled.span`
  border: solid 1px ${mainColor};
  color: ${mainColor};
  padding: 10px 20px;
  text-transform: uppercase;
  background-color: transparent;
  font-family: Fira Sans;
  position: relative;
  display: block;
  z-index: 0;
  transition: 150ms;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background-color: ${({ color }: Props) => (color ? "white" : "black")};
    display: block;
    top: 0;
    left: 50%;
    z-index: -1;
    transition: 250ms;
    opacity: 0;
  }

  &:hover {
    color: ${({ color }: Props) => (color ? color : "white")};
    box-shadow: 0 0 1px rgba(34, 25, 25, 0.4);
    border-radius: 3px;
    &:before {
      width: 100%;
      left: 0;
      opacity: 1;
    }
  }
`;

export default Button;
