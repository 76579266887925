import styled from "styled-components";

export default styled.main`
  position: relative;
  padding: 80px 80px 40px 80px;

  @media screen and (max-width: 1024px) {
    padding: 80px 40px 40px 40px;
  }

  @media screen and (max-width: 768px) {
    padding: 60px 0 0 0;
  }
`;
