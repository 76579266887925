import styled from "styled-components";

import Border from "./border";

export default styled(Border)`
  left: 0;
  bottom: 0;
  right: 0;
  height: 40px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
