import * as React from "react";
import styled from "styled-components";

interface Block {
  title: string;
  content: string;
}

interface Props {
  blocks: Block[];
}

const Container = styled.ul`
  list-style-type: none;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0;
  margin: 0;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Item = styled.li`
  max-width: 300px;
  margin: 30px;

  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

const Title = styled.h3`
  font-family: Bosk;
  font-size: 30px;
  font-weight: 400;
  color: white;
  padding: 15px 0;
  text-align: center;
  margin: 0;
`;

const Content = styled.p`
  font-family: Fira Sans;
  color: white;
  text-align: center;
  font-weight: 300;
`;

const Line = styled.div`
  height: 1px;
  background-color: white;
  width: 68%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Dot = styled.span`
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  position: relative;
  top: -9px;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
`;

const BlocksList = ({ blocks }: Props) => (
  <Wrapper>
    <Line>
      {blocks.map((blocks, i) => (
        <Dot key={i} />
      ))}
    </Line>
    <Container>
      {blocks.map((block, index) => (
        <Item key={index}>
          <Title>{block.title}</Title>
          <Content>{block.content}</Content>
        </Item>
      ))}
    </Container>
  </Wrapper>
);

export default BlocksList;
