import styled, { keyframes } from "styled-components";

interface Props {
  closing: boolean;
  open: boolean;
  closingDuration: number;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2d0e2f;
  opacity: ${({ closing }) => (closing ? 0 : 0.7)};
  z-index: 100001;
  animation: ${fadeIn} 300ms ease-in-out;
  transition: ${({ closingDuration }) => closingDuration}ms;
  display: ${({ open }: Props) => (open ? "block" : "none")};
`;

export default Overlay;
