import * as React from "react";
import { Link } from "gatsby";

import SocialNetworks from "../social-networks";
import { Container, Section, Title, Ul, P } from "./style";

export default function Footer() {
  return (
    <Container>
      <Section>
        <Title>Dankána</Title>
        <P>
          Studio créatif parisien <br />
          Graphisme, web et linogravure
        </P>
      </Section>
      <Section>
        <Title>Liens</Title>
        <nav>
          <Ul>
            <li>
              <Link to="/">Accueil</Link>
            </li>
            <li>
              <Link to="/projects">Réalisations</Link>
            </li>
            <li>
              <Link to="/prints">Linogravures</Link>
            </li>
            <li>
              <Link to="/about">À propos</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </Ul>
        </nav>
      </Section>
      <Section>
        <Title>Réseaux sociaux</Title>
        <SocialNetworks inline />
      </Section>
    </Container>
  );
}
