import styled from "styled-components";

import Border from "./border";

export default styled(Border)`
  top: 0;
  right: 0;
  bottom: 0;
  width: 80px;

  @media screen and (max-width: 1024px) {
    width: 40px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
