import * as React from "react";
import { createGlobalStyle } from "styled-components";
import { ParallaxProvider } from "react-scroll-parallax";
import { Helmet } from "react-helmet";

import BorderContainer from "../border-container";
import { BoskEOT, BoskTTF, BoskWOFF } from "../../shared/css/fonts";

import Header from "../header";
import Sidebar from "../sidebar";
import Footer from "../footer";
import Menu, { Burger, Panel, Overlay } from "../menu";

const Global = createGlobalStyle`
a {
  text-decoration: none;
  color: inherit;
}
body {
  margin: 0;
  padding: 0;
  font-family: Fira Sans;
}
html, body { height: 100%; }
  @font-face {
    font-family: "Bosk";
    font-style: normal;
    font-weight: normal;
    src: local("Bosk"), local("Bosk"), url(${BoskTTF}) format("ttf"), url(${BoskWOFF}) format("woff"), url(${BoskEOT}) format("eot");
  }
`;

interface Props {
  children: React.ReactNode;
}

export default ({ children }: Props) => (
  <ParallaxProvider>
    <Helmet>
      <meta
        name="google-site-verification"
        content="UfWAys8zgbn1IdnhQGmCvSpLg2CpKAoY717uIPK8dhE"
      />
    </Helmet>
    <Global />
    <Menu closingDuration={300}>
      {({ toggle, open, closingDuration, closing }) => (
        <BorderContainer>
          <Burger onClick={toggle} />
          <Overlay
            onClick={toggle}
            open={open}
            closingDuration={closingDuration}
            closing={closing}
          />
          <Panel open={!closing && open} />
          <Header />
          <Sidebar />
          {children}
          <Footer />
        </BorderContainer>
      )}
    </Menu>
  </ParallaxProvider>
);
