import styled from "styled-components";

import OTitle from "../title";

const Title2 = OTitle.withComponent("h2");

export const Title = styled(Title2)`
  color: white;
  text-align: left;
  padding: 5% 0 3% 5%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 70px;
`;

export const Blocks = styled.div`
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-gap: 3.333%;
  box-sizing: border-box;
  max-width: 1000px;
  margin: 50px auto;
  width: calc(100% - 210px);

  @media screen and (max-width: 768px) {
    display: block;
    width: 95%;
  }
`;

export const Block = styled.div`
  background-color: #d66569;
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  font-weight: 200;
  p {
    padding: 30px;
    margin: 0;
  }
  .gatsby-image-outer-wrapper,
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    background-color: transparent;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;
