import * as React from "react";

import Logo from "../logo";

import { Container } from "./style";

export default () => (
  <Container>
    <Logo />
  </Container>
);
