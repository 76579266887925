import styled from "styled-components";

export const Container = styled.footer`
  display: flex;
  width: 100%;
  padding: 50px 0 0 0;
  flex-flow: row wrap;
  color: #777777;
  font-weight: 200;
  max-width: 1200px;
  margin: 50px auto 0 auto;
  border-top: solid 1px #777777;
`;

export const Section = styled.div`
  flex-grow: 1;

  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
`;

export const Title = styled.h3`
  font-weight: 400;
  margin: 0 0 3px 0;
`;

export const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const P = styled.p`
  margin: 0;
`;
