import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import HomeSection from "../home-section";
import BlocksList from "../blocks-list";
import Button from "../button";

const Title = styled.h2`
  font-family: Bosk;
  font-size: 50px;
  font-weight: 400;
  color: white;
  padding: 50px 0;
  text-align: center;
  margin: 0 0 50px 0;

  @media screen and (max-width: 768px) {
    margin: 0 0 20px 0;
    padding: 15px 0;
    font-size: 40px;
  }
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
`;

const blocks = [
  {
    title: "Identité",
    content:
      "Nous produisons des identités visuelles simples et intelligentes, pour des petites ou grandes entreprises ambitieuses."
  },
  {
    title: "Web",
    content:
      "Nous réalisons des sites internet administrables et optimisés. Vous serez formés et autonomes pour gérer votre visibilité digitale."
  },
  {
    title: "Édition",
    content:
      "Nous créons des supports de communication personnalisés et  adaptés à chaque univers de marque ou entreprise."
  }
];

export default () => (
  <HomeSection color="#92b498">
    <Center>
      <div>
        <Title>Notre savoir faire</Title>
        <BlocksList blocks={blocks} />
        <ButtonContainer>
          <Link
            to="/projects"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Button color="#92b498">Voir toutes nos réalisations</Button>
          </Link>
        </ButtonContainer>
      </div>
    </Center>
  </HomeSection>
);
