import styled from "styled-components";

export default styled.h3`
  text-transform: uppercase;
  transform: rotate(-90deg);
  white-space: nowrap;
  margin: 0;
  font-family: Fira Sans;
  font-weight: 400;
`;
