import * as React from "react"
import {Helmet} from "react-helmet"
import {graphql} from "gatsby"

import {ContentfulSeo} from "../../graphql-types"

interface Props {
    data: ContentfulSeo
}

export default function SEO({data}: Props) {
    return (
        <Helmet>
            <title>{data.title}</title>
            <meta name="description" content={data.description} />
        </Helmet>
    )
}

export const query = graphql`
    fragment SEO on ContentfulSeo {
        title
        description
    }
`