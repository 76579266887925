import * as React from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "gatsby";
import { Parallax } from "react-scroll-parallax";

import HomeSection from "../home-section";
import Button from "../button";
import leaves from "./leaves.png";
import { ContentfulHomePage } from "../../graphql-types";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1
  }
`;

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

const Title = styled.h1`
  animation: ${fadeIn} 500ms ease-in-out;
  font-family: Bosk;
  font-size: 70px;
  font-weight: 400;
  white-space: pre-line;
  text-align: center;
  max-width: 450px;
  @media screen and (max-width: 768px) {
    font-size: 40px;
    margin-top: -40%;
  }
`;

const Leaves = styled.img.attrs({ src: leaves, alt: "Dankana" })`
  width: auto;
  height: 100%;
  max-height: 600px;
  position: absolute;
  bottom: 0;
  right: 0;
  @media screen and (max-width: 768px) {
    max-height: 400px;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Text = styled.p`
  text-align: center;
  font-size: 26px;
  margin-top: -20px;
  font-weight: 300;
`;

interface Props {
  page: ContentfulHomePage;
}

export default ({ page }: Props) => (
  <HomeSection color="#d66569">
    <Leaves />
    <Inner>
      <Parallax offsetYMax={80} offsetYMin={-80} slowerScrollRate>
        <Title>{page.title}</Title>
        <Text>Graphisme, web et linogravure</Text>
      </Parallax>
      <ButtonContainer>
        <Link
          to="/projects"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Button color="#d66569">Voir tous les projets</Button>
        </Link>
      </ButtonContainer>
    </Inner>
  </HomeSection>
);
