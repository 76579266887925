import styled from "styled-components";

export default styled.header`
  position: fixed;
  top: 0;
  left: 80px;
  right: 0;
  height: 80px;
  display: flex;
  align-items: center;
  z-index: 2;

  @media screen and (max-width: 1024px) {
    left: 40px;
  }

  @media screen and (max-width: 768px) {
    left: 20px;
    height: 60px;
  }
`;
