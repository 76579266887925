import styled from "styled-components";

interface Props {
  color: string;
}

const getBackgroundColor = ({ color }: Props) => color;

export default styled.section`
  background-color: ${getBackgroundColor};
  position: relative;
  height: calc(100vh - 120px);
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    height: calc(100vh - 105px);
  }

  @media screen and (max-width: 768px) {
    min-height: calc(100vh - 60px);
    height: inherit;
    padding: 30px 0;
    box-sizing: border-box;
  }
`;
