import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const SVG = styled.svg`
  height: 40px;
  width: auto;

  @media screen and (max-width: 768px) {
    height: 30px;
  }
`;

export default () => (
  <Link to="/">
    <SVG x="0px" y="0px" viewBox="0 0 841.9 256.1">
      <path
        d="M129.4,18.5c-11.4,5.4-18.9,62.6-20.5,75.3c-0.1,0.8-1.2,1-1.6,0.3C89.7,66,48.5,66,30,112.4c-19.1,47.5-7,95.7,27.9,109.7
	c27.3,11,38.7-14.2,42.3-25.6c0.3-0.9,1.6-0.7,1.7,0.2c1.4,21.2,6.8,40.4,20.8,40.4c15.1,0,17-16.2,13.9-28.5
	c-3.1-12.4-0.8-40.6,2.3-67.7c3.1-27.1,13.6-70.2,17.8-96.7C160.7,17.6,142.2,12.4,129.4,18.5z M69,202.2
	C46.4,208.9,54.7,86.4,88.8,98.9C99.3,102.8,91.6,195.4,69,202.2z"
      />
      <path
        d="M263.2,208.3c-0.6-25.9,4.1-107,7.4-122.3s28.8-11.7,30.5-2.1c0.8,4.4,0.7,10.6,0.3,13.8c-0.1,0.7,0.7,1.1,1.2,0.7
	c5.1-4.5,21.8-18,39.1-15.4c20.6,3.1,24.4,23.2,23.5,38.3c-0.8,15.1-6.1,76.5-6.4,89.1c-0.2,8.9-3.6,20-19,16.2
	c-15.5-3.8-18.6-33.3-16.3-54.7c2.3-21.4,9.3-59.9,6.3-68c-2.6-7.2-18.6-12.1-28.5,31.7c-6.2,22.5-2.3,72-5.5,85.4
	C293.9,228.9,263.8,234.1,263.2,208.3z"
      />
      <path
        d="M613.8,208.3c-0.6-25.9,4.1-107,7.4-122.3s28.8-11.7,30.5-2.1c0.8,4.4,0.7,10.6,0.3,13.8c-0.1,0.7,0.7,1.1,1.2,0.7
	c5.1-4.5,21.8-18,39.1-15.4c20.6,3.1,24.4,23.2,23.5,38.3c-0.8,15.1-6.1,76.5-6.4,89.1c-0.2,8.9-3.6,20-19,16.2
	c-15.5-3.8-18.6-33.3-16.3-54.7c2.3-21.4,9.3-59.9,6.3-68c-2.6-7.2-18.6-12.1-28.5,31.7c-6.2,22.5-2.3,72-5.5,85.4
	C644.4,228.9,614.3,234.1,613.8,208.3z"
      />
      <path
        d="M235.9,92.9c-26.6-29.3-78.9-16-67.6,8.7c5.6,12.3,17.7,10.2,32.7,3.6c15-6.6,14.5,2.3,15.2,8.9c0.7,6.7,1.5,14.2-5.3,12.8
	c-6.8-1.4-52.5-5.2-59.3,46.4c-6.8,51.6,49.6,58.7,67.9,40.3c0,0,14.6,5.3,18.6-1C248.9,196,262.5,122.2,235.9,92.9z M204.5,198.9
	c-11,6-23,4.1-23.6-14.6c-0.6-18.7,19.3-40.7,32.5-37.1C225,150.4,218.3,191.3,204.5,198.9z"
      />
      <path
        d="M812.5,92.9c-26.6-29.3-78.9-16-67.6,8.7c5.6,12.3,17.7,10.2,32.7,3.6c15-6.6,14.5,2.3,15.2,8.9c0.7,6.7,1.5,14.2-5.3,12.8
	c-6.8-1.4-52.5-5.2-59.3,46.4s49.6,58.7,67.9,40.3c0,0,14.6,5.3,18.6-1C825.4,196,839.1,122.2,812.5,92.9z M781.1,198.9
	c-11,6-23,4.1-23.6-14.6c-0.6-18.7,19.3-40.7,32.5-37.1C801.6,150.4,794.9,191.3,781.1,198.9z"
      />
      <path
        d="M586.7,92.9c-26.6-29.3-78.9-16-67.6,8.7c5.6,12.3,17.7,10.2,32.7,3.6c15-6.6,14.5,2.3,15.2,8.9c0.7,6.7,1.5,14.2-5.3,12.8
	c-6.8-1.4-52.5-5.2-59.3,46.4c-6.8,51.6,49.6,58.7,67.9,40.3c0,0,14.6,5.3,18.6-1C599.6,196,613.3,122.2,586.7,92.9z M555.3,198.9
	c-11,6-23,4.1-23.6-14.6c-0.6-18.7,19.3-40.7,32.5-37.1C575.7,150.4,569.1,191.3,555.3,198.9z"
      />
      <path
        d="M545.1,62c-5.5-5.1-8.7-10.8-2.6-20.7s10.2-16.6,11.8-20.2c1.6-3.5,5.4-9.2,16.3-5.8c10.9,3.4,12.4,13.2,7.8,24.4
	c-4.5,11.2-13.1,34-27.7,26.8L545.1,62z"
      />
      <path
        d="M488.7,139.5c8.9-15.4,9.9-57.1-24.9-57.1c-28.9,0-44,22-48.3,29.4c-0.3,0.4-0.9,0.3-0.9-0.3c1.4-11.5,4.3-53.1,5.7-73
	c0.6-8.8-4-17.5-12.1-20.9c-7.2-3-15.8-1.9-21.6,14.7c-11.7,33.3-7.3,157-4.7,176.2c0,0,2.8,14.4,22.3,17.8
	c17.9,3,9.8-38.9,8.3-45.9c-0.1-0.5,0.3-0.9,0.8-0.8c3,0.5,12.5,0.3,21.4,2.2c13.7,2.9,27.6,23.7,35.2,34.6c1.8,2.6,4.6,4.6,7.7,5.1
	c11.1,2,14.7-5.7,13.6-13.9c-1.6-11.6-23.8-34.2-29.3-40.2c-0.6-0.7-0.4-1.7,0.3-2.2C466.9,162.3,481.3,152.3,488.7,139.5z
	 M439.5,135.7c-12.2,8.5-21.9,10.7-25.2,12.1c-0.6,0.3-1.3-0.3-1.2-0.9c1.1-6.1,6.9-29.4,34-43.4
	C478.5,87.3,461.4,120.4,439.5,135.7z"
      />
    </SVG>
  </Link>
);
